<template>
  <CRow>
    <CCol col>
      <CCard>
        <CCardHeader>
          <CRow class="justify-content-between px-3">
            <h5>Consumers</h5>
            <div class="d-flex">
              <CSelect
                style="width: 180px"
                class="mx-2 my-0"
                horizontal
                label="Type"
                :value.sync="search.type"
                :options="search.options"
                @change="searchChangeType"
              />
              <CInput
                style="width: 400px"
                class="my-0 px-0"
                :placeholder="`Search ${search.placeholder}`"
                :value.sync="search.value"
              >
                <template #append>
                  <CButton color="primary" @click="doApplyFilter"
                    >Search</CButton
                  >
                  <CButton
                    class="ml-2"
                    size="sm"
                    color="info"
                    variant="outline"
                    @click="isShowFilter = !isShowFilter"
                  >
                    <CIcon
                      :name="isShowFilter ? 'cil-x' : 'cil-arrow-bottom'"
                    />
                    Filter</CButton
                  >
                </template>
              </CInput>
            </div>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CCollapse :show="isShowFilter">
            <CCard body-wrapper>
              <CRow>
                <h5 class="ml-3 flex-grow-1">Filter</h5>
                <CButton
                  class="ml-auto m-2"
                  variant="outline"
                  color="info"
                  @click="doResetFilter"
                  >Reset</CButton
                >
                <CButton class="m-2" color="info" @click="onApplyFilter"
                  >Apply</CButton
                >
              </CRow>
              <CRow>
                <CCol>
                  <treeselect
                    v-model="filter.status"
                    :multiple="true"
                    :options="filterData.status"
                    placeholder="Select status"
                  >
                    <div class="d-grid gap-4 d-md-flex" slot="before-list">
                      <CButton
                        class="mx-2"
                        size="sm"
                        variant="outline"
                        color="info"
                        @click="selectAll"
                        >Select All</CButton
                      >
                      <CButton
                        size="sm"
                        variant="outline"
                        color="info"
                        @click="deselectAll"
                        >Deselect All</CButton
                      >
                    </div>
                  </treeselect>
                </CCol>

                <CCol>
                  <date-picker
                    v-model="filter.dateRangeSelected"
                    range
                    format="YYYY-MM-DD"
                    placeholder="Created date"
                  ></date-picker>
                </CCol>
              </CRow>
            </CCard>
          </CCollapse>
          <CDataTable
            hover
            striped
            sorter
            border
            :loading="loading"
            :itemsPerPageSelect="false"
            :items="users"
            :fields="fields"
            :items-per-page="pagination.limit"
            :pagination="false"
          >
            <td slot="id" slot-scope="{ item }">
              <CLink :href="'#/consumers/' + item.id">
                {{ item.id }}
              </CLink>
            </td>

            <td slot="status" slot-scope="{ item }">
              <CBadge :color="getUserBadge(item.status)">
                {{ item.status }}
              </CBadge>
            </td>

            <td slot="phone_number" slot-scope="{ item }">
              {{ item.phone_number ? item.phone_number : '-' }}
            </td>

            <td slot="stripe_id" slot-scope="{ item }">
              <span
                v-if="item.user_setting && item.user_setting.stripe_customer_id"
              >
                <CLink
                  :href="
                    env.stripeURL +
                    '/customers/' +
                    item.user_setting.stripe_customer_id
                  "
                  target="_blank"
                >
                  {{ item.user_setting.stripe_customer_id }}
                </CLink>
              </span>
              <span v-else>-</span>
            </td>
            <td slot="provider_id" slot-scope="{ item }">
              <span
                v-if="item.user_setting && item.user_setting.owner_provider_id"
              >
                <CLink :href="'#/sp/' + item.user_setting.owner_provider_id">
                  {{ item.user_setting.owner_provider_id }}
                </CLink>
              </span>
              <span v-else>-</span>
            </td>

            <td slot="last_login" slot-scope="{ item }">
              {{ moment(item.last_login).fromNow() }}
            </td>
            <td slot="created_at" slot-scope="{ item }">
              {{ item.created_at | moment(datetimeFormat) }}
            </td>
          </CDataTable>

          <MyPagination
            :limit="pagination.limit"
            :total="pagination.total"
            :activePage="pagination.page"
            @changePage="onChangePage"
            @changePageSize="onChangePageSize"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { ACTION_FETCH_USERS_PAGING } from '@/store/actions.type';
import { mapGetters } from 'vuex';
import { UserBadge } from '@/utils/utils';
import { Common } from '@/constants';
import env from '@/constants/env';
import moment from 'moment';
import MyPagination from '../../components/MyPagination.vue';

export default {
  name: 'Consumers',
  components: { MyPagination },
  data() {
    return {
      env,
      datetimeFormat: Common.datetimeFormat,
      loading: true,
      fields: [
        { key: 'status' },

        {
          key: 'id',
          label: 'UserID',
          _style: 'width: 20px',
        },
        { key: 'name', label: 'Name', _classes: 'font-weight-bold' },
        { key: 'email' },
        { key: 'phone_number' },
        { key: 'provider_id' },
        { key: 'stripe_id', label: 'Stripe Customer' },
        { key: 'last_login', label: 'LastLogin' },
        { key: 'created_at', label: 'CreatedAt' },
      ],
      isShowFilter: false,
      search: {
        type: 'name',
        value: '',
        placeholder: 'Name',
        options: [
          { value: 'name', label: 'Name' },
          { value: 'id', label: 'Id' },
          { value: 'email', label: 'Email' },
          { value: 'phone_number', label: 'Phone' },
        ],
      },
      filter: {
        status: null,
        dateRangeSelected: null,
      },
      filterData: {
        status: [
          { id: 'active', label: 'Active' },
          { id: 'inactive', label: 'Inactive' },
          { id: 'banned', label: 'Banned' },
          { id: 'deleted', label: 'Deleted' },
        ],
        categories: [],
      },
      pagination: {
        page: 1,
        limit: 25,
        total: 0,
      },
    };
  },
  mounted() {
    this.doApplyFilter();
    // this.$store.dispatch(ACTION_FETCH_USERS_PAGING, {}).then(() => {
    //   this.loading = false;
    // });
  },
  computed: {
    ...mapGetters(['users']),
    ...mapGetters({
      paging: 'pagingUsers',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.pagination.page = Number(route.query.page);
        }

        if (route.query && route.query.limit) {
          this.pagination.limit = Number(route.query.limit);
        }
      },
    },
  },
  methods: {
    updateURL() {
      this.$router.push({
        query: {
          page: this.pagination.page,
          limit: this.pagination.limit,
        },
      });
    },

    getUserBadge(status) {
      return UserBadge(status);
    },
    rowClicked(item) {
      this.$router.push({ path: `consumers/${item.id}` });
    },
    pageChanged(val) {
      this.$router.push({ query: { page: val } });
    },
    searchChangeType() {
      this.search.placeholder = this.search.options.find(
        (x) => x.value == this.search.type
      ).label;
      this.search.value = '';
    },
    onChangePage(val) {
      this.pagination.page = val;
      this.updateURL();
      this.doApplyFilter();
    },
    onChangePageSize(val) {
      this.pagination.limit = val;
      this.pagination.page = 1;

      this.updateURL();
      this.doApplyFilter();
    },
    selectAll() {
      this.filter.status = [];
      this.filterData.status.forEach((value) => {
        this.filter.status?.push(value.id);
      });
    },
    deselectAll() {
      this.filter.status = [];
    },
    onApplyFilter() {
      this.pagination.page = 1;
      this.pagination.limit = 25;

      this.doApplyFilter();
    },
    doApplyFilter() {
      let query = {
        limit: this.pagination.limit,
        page: this.pagination.page,
      };

      if (this.search.value?.length > 0) {
        switch (this.search.type) {
          case 'name': {
            query = {
              ...query,
              name: this.search.value,
            };
            break;
          }
          case 'id': {
            query = {
              ...query,
              id: this.search.value,
            };
            break;
          }
          case 'email': {
            query = {
              ...query,
              email: this.search.value,
            };
            break;
          }
          case 'phone_number': {
            query = {
              ...query,
              phone_number: this.search.value,
            };
            break;
          }
        }
      }

      if (this.filter.status?.length > 0) {
        query = {
          ...query,
          status: this.filter.status,
        };
      }

      if (
        this.filter.dateRangeSelected?.length > 0 &&
        this.filter.dateRangeSelected[0] &&
        this.filter.dateRangeSelected[1]
      ) {
        query = {
          ...query,
          from: moment(this.filter.dateRangeSelected[0])
            .startOf('day')
            .format('YYYY-MM-DD'),
          to: moment(this.filter.dateRangeSelected[1])
            .endOf('day')
            .format('YYYY-MM-DD'),
        };
      }
      this.loading = true;
      this.$store
        .dispatch(ACTION_FETCH_USERS_PAGING, query)
        .then(() => {
          this.loading = false;
          this.pagination = {
            limit: Number(this.paging.limit),
            total: Number(this.paging.total_rows),
            page: Number(this.paging.page),
          };
        })
        .catch((err) => {
          console.error(err);
        });
    },
    doResetFilter() {
      this.filter.status = null;
      this.filter.dateRangeSelected = null;
      this.pagination.limit = 25;
      this.pagination.page = 1;

      this.updateURL();
      this.doApplyFilter();
    },
  },
};
</script>
