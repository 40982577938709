<template>
  <div id="myPagination" class="staking-pagination">
    <div class="row">
      <div class="col-ld-12 col-md-12 col-sm-12 text-right">
        <div class="form-group page-size-options mr-2">
          <select
            :value="limit"
            class="form-control custom-select mr-2"
            @change="onChangePageSize($event)"
          >
            <option
              v-for="item in pageSizeOptions"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </option>
          </select>
          <span>items</span>
        </div>
        <div class="pagination-wrap">
          <CPagination
            v-if="pages > 1"
            :activePage="activePage"
            :pages="pages"
            align="end"
            size
            @update:activePage="onChangePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyPagination',
  data() {
    return {
      pageSizeOptions: [
        { value: 5, label: '5' },
        { value: 10, label: '10' },
        { value: 25, label: '25' },
        { value: 50, label: '50' },
      ],
    };
  },
  props: {
    activePage: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 25,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  methods: {
    async onChangePage(index) {
      this.$emit('changePage', index);
    },
    async onChangePageSize(event) {
      this.$emit('changePageSize', Number(event.target.value));
    },
  },
};
</script>

<style lang="scss">
.staking-pagination {
  .page-size-options {
    display: inline-block;
    select {
      display: inline-block;
      width: 80px;
    }
    span {
      font-size: 12px;
      color: #657187;
    }
  }

  .pagination-wrap {
    display: inline-block;
    .page-item {
      &.active {
        .page-link {
          background-color: #586591;
          border-color: #586591;
        }
      }
    }
  }
}
</style>
